import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import QueryControl from './queryControl';
import { Container as BaseContainer } from '../typography';
import { BLUE, MEDIUM_GRAY, WHITE } from '../../constants/colors';
import { media } from '../../constants/screens';

// eslint-disable-next-line
class Tabs extends React.Component {
  render() {
    const { tabs } = this.props;
    return (
      <QueryControl
        label="tab"
        initialValue={tabs.length ? tabs[0].query : ''}
      >
        {({ currentValue, set }) => {
          const currentTabIndex = tabs.findIndex(({ query }) => query === currentValue);
          return (
            <Container>
              <Buttons>
                {tabs.map(({ label, query, isNew }, i) => (
                  <Button
                    key={query}
                    data-title={label}
                    onClick={() => set(query)}
                    type="button"
                    isActive={i === currentTabIndex}
                    isNew={isNew}
                  >
                    {label}
                  </Button>
                ))}
              </Buttons>
              <TabsContainer>
                {tabs.map(({ content, query }, i) => (
                  <Tab isActive={i === currentTabIndex} key={query}>
                    {content()}
                  </Tab>
                ))}
              </TabsContainer>
            </Container>
          );
        }
        }
      </QueryControl>
    );
  }
}

Tabs.defaultProps = {
  tabs: [],
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
};

const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  box-sizing: border-box;
  
  ${media.tablet`
    position: sticky;
    z-index: 10;
    top: 65px;
    margin-bottom: 0;
    background: ${WHITE};
    padding: 15px 5%;
    top: 65px;
    box-shadow: 0px -6px 20px -17px rgba(68,68,68,0.6) inset;
  `}
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  min-height: 110px;
  background: ${MEDIUM_GRAY};
  font-size: 24px;
  color: black;
  appearance: none;
  max-width: 630px;
  width: 50%;
  box-shadow: 0 0 36px 4px rgba(220, 227, 238, 0.61);
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  transition: all 0.2s;
  outline: none;
  
  &:before {
    content: attr(data-title);
    font-family: AvenirNextLTProBold;
    visibility: hidden;
    height: 0;
  }
  
  &:not(:last-child) {
    margin-right: 15px;
  }
  
  ${media.laptop`
    font-size: 18px;
    min-height: 80px;
  `}
  
  ${media.tablet`
    font-size: 16px;
    min-height: 70px;
  `}
  
  ${media.mobile`
    font-size: 12px;
    padding: 10px 15px;
    min-height: 0;
  `}
  
  ${({ isActive }) => isActive && css`
    background: white;
    font-weight: bold;
    font-family: AvenirNextLTProBold;
    color: ${BLUE};
  `}
  
  ${({ isNew }) => isNew && css`
    position: relative;
    overflow: hidden;
    
    &:after {
       padding: 25px 20px 3px;
      content: 'new';
      position: absolute;
      right: 0;
      top: 0;
      background: red;
      font-size: 10px;
      color: ${WHITE};
      font-family: AvenirNextLTPro;
      font-weight: 400;
      transform: rotate(45deg);
      transform-origin: 91% 111%;
      
      ${media.laptop`
        font-size: 7px;
        padding-bottom: 2px;
        transform-origin: 100% 112%;
      `}
    }
  `}
`;

const TabsContainer = styled.ul`
  width: 100%;
  box-shadow: 0 0 36px 4px rgba(220, 227, 238, 0.61);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  
  ${media.tablet`
    border-radius: 0;
    margin: 0 -4.5%;
  `}
`;

const Tab = styled.li`
  opacity: 0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  z-index: 0;
  padding: 0 5% 80px;
  box-sizing: border-box;
  
  ${({ isActive }) => isActive && css`
    position: relative;
    opacity: 1;
    z-index: 1;
  `}
  
  ${media.tablet`
    padding: 0;
  `}
`;

const Container = styled(BaseContainer)`
  padding-top: 75px;
  padding-bottom: 50px;
  
  ${media.laptop`
    padding-top: 60px;
    padding-bottom: 40px;
  `}
  
  ${media.tablet`
    padding: 60px 0 40px;
  `}
  
  ${media.mobile`
    padding: 60px 0 60px;
  `}
`;

export default Tabs;
