import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import Info from '../common/info';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, TABLET, media,
} from '../../constants/screens';
import { BLACK, BLUE, DARK_GRAY } from '../../constants/colors';
import { getImageSizes } from '../../utils';

const Introduction = ({
  image,
  title,
  subTitle,
  description,
  switchTitleAndSubtitle,
  ...rest
}) => (
  <Container {...rest}>
    <Content>
      <AboutUsItem
        reversed
        image={getImageSizes(image)}
        maxImageWidth="665px"
        imgAltAttr="developer satisfaction tracker"
      >
        <Info
          reversed
          title={title}
          subTitle={subTitle}
          text={description}
          titleColor={DARK_GRAY}
          subTitleColor={BLUE}
          textColor={BLACK}
          subTitleFontSize="40px"
          pageName="developer-satisfaction-tracker"
          switchTitleAndSubtitle={switchTitleAndSubtitle}
        />
      </AboutUsItem>
    </Content>
  </Container>
);

Introduction.propTypes = {
  image: PropTypes.shape(),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  switchTitleAndSubtitle: PropTypes.bool,
};

Introduction.defaultProps = {
  image: {},
  subTitle: '',
  title: '',
  description: '',
  switchTitleAndSubtitle: false,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 300px 5% 75px 5%;
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 75px 5%;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: 1600px) {
    padding: 225px 5% 75px 5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
  }
  
  > div {
    width: 100%;
    
    @media screen and (min-width: ${TABLET}) {
      > div:first-child {
        > div {
          > div {
            justify-content: flex-start;
            width: 100%;
          }
          > h2, h3, h4, p, h1 {
            align-self: flex-start;
            text-align: left;
          }
          > h4 {
            max-width: 325px;
          }
        }
      }
    }
  }
`;

export default Introduction;
