import React from 'react';
import PropTypes from 'prop-types';
import { getQueryString, parseQuery } from '../../utils/common';
import { LayoutConsumer } from '../layout';

const setQueryValue = ({
  value, label, location, history,
}) => {
  const query = parseQuery(location.search);
  const queryString = getQueryString({ ...query, [label]: value });
  history.replaceState({}, '', `${location.pathname}?${queryString}`);
};

export const getQueryValue = ({ location, label }) => parseQuery(location.search)[label];

const location = typeof window !== 'undefined' && window.location;

const QueryControl = ({
  children, label, initialValue,
}) => (
  <>
    {
      // eslint-disable-next-line
      !!Object.keys(location).length ? (
        <LayoutConsumer>
          {({ history }) => (
            <>
              {children({
                set: value => setQueryValue({
                  value, label, location, history,
                }),
                currentValue: getQueryValue({ location, label }) || initialValue,
              })}
            </>
          )}
        </LayoutConsumer>
      ) : null
    }
  </>
);

QueryControl.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

QueryControl.defaultProps = {
  initialValue: '',
  label: '',
  children: () => null,
};

export default QueryControl;
