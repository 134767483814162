import styled from 'styled-components';
import Introduction from './introduction';
import { media } from '../../constants/screens';
import { AboutUsItemImageContainer, AboutUsItemInfoWrapper } from '../common/aboutUsItem';

export const TabIntroduction = styled(Introduction)`
  padding: 150px 5% 50px; 
  
  ${media.laptop`
    padding: 120px 5% 30px;
  `}
  
  ${media.tablet`
    padding-top: 80px;
    padding-bottom: 30px;
  `}
  
  & ${AboutUsItemImageContainer} {
    ${media.tablet`
      order: 1;
      margin-bottom: 100px;
    `}
    
    ${media.mobile`
      margin-bottom: 60px;
    `}
  }
  
  & ${AboutUsItemInfoWrapper} {
    ${media.tablet`
      order: 2;
      margin-bottom: 0;
    `}
  }
`;
