import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { graphql } from 'gatsby';
import Introduction from '../components/ttPage/introduction';
import { media } from '../constants/screens';
import { DARK_SCHEME, WHITE } from '../constants/colors';
import withCustomLayout from '../enhancers/withCustomLayout';
import withStickyHeader from '../enhancers/withStickyHeader';
import withChildRoutes from '../enhancers/withChildRoutes';
import BrochureRequest from '../components/brochureRequestPage';
import Tabs from '../components/common/tabs';
import ImageSection from '../components/common/imageSection';
import Questions from '../components/common/questions';
import Section from '../components/common/section';
import ReportsControlled from '../components/common/reportsControlled';
import ContactUsWhite from '../components/common/contactUsWhite';
import { TabIntroduction } from '../components/ttPage/tags';

const TTPage = (props) => {
  const {
    data: {
      page: {
        introduction, tab1Title, tab1, is1TabNew, tab2Title, tab2, is2TabNew,
      },
      dsts: { edges: dsts },
      dstCategories: { edges: dstCategories },
      tats: { edges: tats },
      tatCategories: { edges: tatCategories },
    },
  } = props;

  const tab1SectionImage = tab1.filter(({ meta }) => meta && meta.includes('image'));
  const tab1Questions = tab1.filter(({ meta }) => meta && meta.includes('questions'));
  const contactUsSectionTab1 = tab1.filter(({ meta }) => meta && meta.includes('contact'));

  const tab2SectionImage = tab2.filter(({ meta }) => meta && meta.includes('image'));
  const tab2Questions = tab2.filter(({ meta }) => meta && meta.includes('questions'));
  const contactUsSectionTab2 = tab2.filter(({ meta }) => meta && meta.includes('contact'));

  return (
    <>
      <Introduction {...introduction} />
      <Tabs
        tabs={[{
          label: tab1Title,
          query: 'dst',
          isNew: is1TabNew,
          content: () => (
            <>
              {tab1[0] && (
                <TabIntroduction
                  {...tab1[0]}
                  switchTitleAndSubtitle
                />
              )}
              {tab1SectionImage && (
                <ImageSection
                  reversed={false}
                  {...tab1SectionImage[0]}
                />
              )}
              {tab1Questions && (
                <Questions
                  {...tab1Questions[0]}
                  bg={WHITE}
                  css={`
                    padding-bottom: 100px;
                    ${media.laptop`padding-bottom: 60px;`}
                    ${media.tablet`padding-bottom: 0;`}
                  `}
                />
              )}
              {tab1[3] && (
                <Section
                  {...tab1[3]}
                  bg={WHITE}
                  css={`
                    padding-bottom: 100px;
                    ${media.laptop`padding-bottom: 60px;`}
                    ${media.tablet`padding-bottom: 0;`}
                  `}
                />
              )}
              {dsts && (
                <ReportsControlled categories={dstCategories} items={dsts} />
              )}
              {contactUsSectionTab1 && (
                <ContactUsWhite
                  {...contactUsSectionTab1[0]}
                  css={`
                    width: 70%; margin: 0 auto; padding: 120px 5% 150px;
                    ${media.tablet`
                      width: 100%;
                      padding: 80px 5% 60px;
                    `}
                  `}
                />
              )}
            </>
          ),
        },
        {
          label: tab2Title,
          query: 'tat',
          isNew: is2TabNew,
          content: () => (
            <>
              {tab2[0] && (
                <TabIntroduction
                  {...tab2[0]}
                  switchTitleAndSubtitle
                />
              )}
              {tab2SectionImage && (
                <ImageSection
                  reversed={false}
                  {...tab2SectionImage[0]}
                />
              )}
              {tab2Questions && (
                <Questions
                  {...tab2Questions[0]}
                  bg={WHITE}
                  css={`
                    padding-bottom: 100px;
                    ${media.laptop`padding-bottom: 60px;`}
                    ${media.tablet`padding-bottom: 0;`}
                  `}
                />
              )}
              {tab2[3] && (
                <Section
                  {...tab1[3]}
                  bg={WHITE}
                  css={`
                    padding-bottom: 100px;
                    ${media.laptop`padding-bottom: 60px;`}
                    ${media.tablet`padding-bottom: 0;`}
                  `}
                />
              )}
              {tats && (
                <ReportsControlled categories={tatCategories} items={tats} />
              )}
              {contactUsSectionTab2 && (
                <ContactUsWhite
                  {...contactUsSectionTab2[0]}
                  css={`
                    width: 70%; margin: 0 auto; padding: 120px 5% 150px;
                    ${media.laptop`
                      padding: 80px 5% 60px;
                    `}
                    ${media.tablet`
                      width: 100%;
                      padding: 80px 5% 60px;
                    `}
                  `}
                />
              )}
            </>
          ),
        }]}
      />
    </>
  );
};

TTPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default compose(
  withChildRoutes({
    defaultRoute: '/technology-trackers/',
    childRoutes: [
      { path: '/technology-trackers/:slug', component: BrochureRequest },
    ],
  }),
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(TTPage);

// eslint-disable-next-line
export const query = graphql`
  query TTPageQuery {
    page(url: { regex: "/technology-trackers/" }) {
      introduction {
        title
        subTitle
        description
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 700) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      tab1Title
      tab1 {
        title
        subTitle
        meta
        image {
          file {
            localFile {
              publicURL
              childImageSharp {
                sizes(maxWidth: 1000) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        button {
          url
          text
        }
        description
        content {
          id: _id
          title
          description {
            md
          }
        }
      }
      is1TabNew
      tab2Title
      tab2 {
        title
        subTitle
        meta
        image {
          file {
            localFile {
              publicURL
              childImageSharp {
                sizes(maxWidth: 1000) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        button {
          url
          text
        }
        description
        content {
          id: _id
          title
          description {
            md
          }
        }
      }
      is2TabNew
    }
    dsts: allDst(sort: { fields: [ publishedAt ], order: [ DESC ] }) {
      edges {
        node {
          id
          title
          subtitle
          slug
          meta {
            description
          }
          publishedAt(formatString: "MMMM YYYY")
          categories {
            id: _id
            name
            slug
          }
          cover {
            localFile{
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          brochure {
            url
          }
        }
      }
    }
    dstCategories: allDstCategory {
      edges {
        node {
          id: _id
          name
          slug
        }
      }
    }
    tats: allTat(sort: { fields: publishedAt, order: ASC }) {
      edges {
        node {
          id
          title
          subtitle
          slug
          meta {
            description
          }
          excerpt
          publishedAt(formatString: "MMMM YYYY")
          categories {
            id: _id
            name
            slug
          }
          cover {
            localFile{
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
          brochure {
            url
          }
        }
      }
    }
    tatCategories: allTatCategory {
      edges {
        node {
          id: _id
          name
          slug
        }
      }
    }
  }
`;
