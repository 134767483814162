import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  map, compose, path, filter, contains, find, ifElse, type, equals,
} from 'ramda';
import { Container as BaseContainer } from '../../typography';
import Item from '../reportItem/item';
import Collapse from '../collapse';
import QueryControl, { getQueryValue } from '../queryControl';
import { stringToBoolean } from '../../../utils';
import { LayoutConsumer } from '../../layout';
import { DARK_GRAY_2 } from '../../../constants/colors';
import { media } from '../../../constants/screens';
import CheckboxBlue from '../checkboxBlue';

const getEnabledCategories = (categories, location) => compose(
  map(path(['node', 'id'])),
  filter(c => stringToBoolean(ifElse(
    v => equals(type(v), 'Undefined'),
    () => true,
    v => v,
  )(getQueryValue({ location, label: path(['node', 'slug'], c) })))),
)(categories);

const filterItems = (enabledCategoriesId, items) => items.filter(
  item => find(
    c => contains(
      c.id, enabledCategoriesId,
    ),
    path(['node', 'categories'], item),
  ),
);

const ReportsControlled = ({ categories, items }) => (
  <Collapse duration={500}>
    {
      ref => (
        <Container ref={ref}>
          <Checkboxes>
            {categories.map(({ node: { slug, name } }) => (
              <QueryControl
                label={slug}
                initialValue
                key={slug}
              >
                {({ currentValue, set }) => (
                  <CheckboxBlue
                    value={stringToBoolean(currentValue)}
                    label={name}
                    onChange={set}
                  />
                )}
              </QueryControl>
            ))}
          </Checkboxes>
          <LayoutConsumer>
            {({ location }) => {
              const enabledCategoriesId = getEnabledCategories(categories, location);
              const availableItems = filterItems(enabledCategoriesId, items);

              return (
                <>
                  {availableItems.length ? (
                    <Items>
                      {availableItems.map(({ node }) => <Item key={node.id} {...node} />)}
                    </Items>
                  ) : (
                    <NoItems>
                    No items found
                    </NoItems>
                  )}
                </>
              );
            }}
          </LayoutConsumer>
        </Container>
      )
    }
  </Collapse>
);

ReportsControlled.defaultProps = {
  items: [],
  categories: [],
};

ReportsControlled.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
};

const Checkboxes = styled.ul`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 60px 30px;
  box-sizing: border-box;
  
  & label:not(:last-child) {
    margin-right: 20px;
  }
  
  ${media.mobile`
    flex-wrap: wrap;
    padding: 20px 10px;
    
    & label {
      &:not(:last-child) {
        margin-right: 8px;
      }
      flex-basis: calc(50% - 8px);
      margin-right: 8px;
      margin-bottom: 10px;
    }
  `}
  
`;

const Items = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
`;

const NoItems = styled.div`
  font-size: 40px;
  font-family: BrandonGrotesque;
  color: ${DARK_GRAY_2};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  
  ${media.mobile`
    font-size: 30px;
    min-height: 30vh;
  `}
`;

const Container = styled(BaseContainer)`
  padding-top: 0;
`;

export default ReportsControlled;
