import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Check } from 'styled-icons/fa-solid/Check';
import { BLUE, MEDIUM_GRAY, WHITE } from '../../constants/colors';
import { media } from '../../constants/screens';

const CheckboxBlue = ({ value, label, onChange }) => (
  <Label checked={value}>
    <Input
      type="checkbox"
      name="dst-category"
      checked={value}
      onChange={e => onChange(e.target.checked)}
    />
    <Icon checked={value}>
      {value ? <Check size={12} color={WHITE} /> : null}
    </Icon>
    <Span data-label={label}>{label}</Span>
  </Label>
);

CheckboxBlue.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

CheckboxBlue.defaultProps = {
  value: false,
  label: '',
  onChange: () => null,
};

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: AvenirNextLTPro;
  text-transform: uppercase;
  font-size: 17px;
  
  ${({ checked }) => checked && css`
    color: ${BLUE};
    font-weight: 700;
    font-family: AvenirNextLTProBold;
  `}
  
  ${media.laptop`
    font-size: 15px;
  `}
  
  ${media.mobile`
    font-size: 12px;
  `}
`;

const Span = styled.span`
  display: flex;
  flex-direction: column;
  
  &:before {
    display: block;
    content: attr(data-label);
    font-family: AvenirNextLTProBold;
    visibility: hidden;
    height: 0;
  }
`;

const Icon = styled.span`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 2px ${MEDIUM_GRAY};
  margin-right: 12px;
  transition: all 0.2s ease-in-out;
  
  ${media.laptop`
    width: 18px;
    height: 18px;
    
    > svg {
      width: 10px;
      height: 10px;
    }
  `};
  
  ${({ checked }) => checked && css`
    border-color: ${BLUE};
    background: ${BLUE};
  `}
`;

const Input = styled.input`
  border: none;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  visibility: hidden;
`;

export default CheckboxBlue;
